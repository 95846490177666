@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.modal__header {
  height: 80px;
  padding: 24px;
  background: var(--button_primary_background-color_default);
  width: 90%;
  .icon {
    height: 100%;
    width: 100%;
    color: #fff;
  }
}
.modal__contents {
  padding: 16px 24px 0px 24px;
  min-height: auto !important;
  .purpose {
    margin-bottom: 0;
    .characters-left {
      margin-bottom: 10px;
      justify-content: right;
      display: flex;
    }
  }
  .modal__title {
    color: var(--base-global-primary-mono, #222428);
    font-family: Museo Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
  }
  .modal__text {
    color: var(--base-global-primary-mono, #222428);
    font-family: Museo Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  button {
    width: 100% !important;
    margin-bottom: 24px !important;
  }
}
