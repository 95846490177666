@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.nav-items {
  position: relative;
}
.nav-items.sticky {
  margin-top: 0;
  position: fixed;
  max-height: v-bind('stickyPosition.maxHeight');
  top: v-bind('stickyPosition.top');
  width: v-bind('stickyPosition.width');
}
