@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.notification.custom {
  background-color: v-bind('notification.background_color') !important;

  p {
    color: v-bind('notification.text_color') !important;
  }

  .actions .icon {
    color: v-bind('notification.text_color') !important;
  }

  .view-link {
    color: v-bind('notification.text_color') !important;
  }
}
