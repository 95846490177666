@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";

#beam_logo {
  display: block;
  height:1.5rem;
  width: 100px;
}

  #beam_logo_bar {
    fill: $orange;
  }

  #beam_logo_name {
    fill: #ffffff;
  }

