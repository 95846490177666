@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
// /*
// * @tokens Audio Player
// */

$audio-player-button-play-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='15' viewBox='0 0 11 15' fill='none'%3E%3Cpath d='M0 14.925V0.925049L11 7.92505L0 14.925Z' fill='%23222428'/%3E%3C/svg%3E");
$audio-player-button-pause-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M8.875 14V0H13.25V14H8.875ZM0.75 14V0H5.125V14H0.75Z' fill='%23222428'/%3E%3C/svg%3E");
$audio-player-button-stop-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M0 0H48V48H0V0Z' fill='black'/%3E%3C/svg%3E");
$audio-player-button-volume-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clip-path='url(%23clip0_295_715)'%3E%3Cpath d='M3 8.99998V15H7L12 20V3.99998L7 8.99998H3ZM16.5 12C16.5 10.23 15.48 8.70998 14 7.96998V16.02C15.48 15.29 16.5 13.77 16.5 12ZM14 3.22998V5.28998C16.89 6.14998 19 8.82998 19 12C19 15.17 16.89 17.85 14 18.71V20.77C18.01 19.86 21 16.28 21 12C21 7.71998 18.01 4.13998 14 3.22998Z' fill='%23222428'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_295_715'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
$audio-player-button-mute-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M20.3251 22.6L17.0251 19.3001C16.5584 19.6334 16.0543 19.9209 15.5126 20.1625C14.9709 20.4042 14.4084 20.5917 13.8251 20.725V19.1751C14.2084 19.0584 14.5793 18.9292 14.9376 18.7875C15.2959 18.6459 15.6334 18.4584 15.9501 18.225L11.8251 14.075V20L6.8251 15H2.8251V9.00005H6.7251L1.2251 3.50005L2.3001 2.42505L21.4001 21.5L20.3251 22.6ZM19.4251 16.8L18.3501 15.725C18.6834 15.1584 18.9293 14.5584 19.0876 13.925C19.2459 13.2917 19.3251 12.6417 19.3251 11.975C19.3251 10.2584 18.8251 8.72088 17.8251 7.36255C16.8251 6.00422 15.4918 5.14172 13.8251 4.77505V3.22505C15.8918 3.69172 17.5751 4.73755 18.8751 6.36255C20.1751 7.98755 20.8251 9.85838 20.8251 11.975C20.8251 12.825 20.7084 13.6584 20.4751 14.475C20.2418 15.2917 19.8918 16.0667 19.4251 16.8ZM16.0751 13.45L13.8251 11.2V7.95005C14.6084 8.31672 15.2209 8.86672 15.6626 9.60005C16.1043 10.3334 16.3251 11.1334 16.3251 12C16.3251 12.25 16.3043 12.4959 16.2626 12.7375C16.2209 12.9792 16.1584 13.2167 16.0751 13.45ZM11.8251 9.20005L9.2251 6.60005L11.8251 4.00005V9.20005Z' fill='%23222428'/%3E%3C/svg%3E");

//component wrapper

.audio-wrapper {
  height: 100%;
  background-color: #E1E4E7;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-area: preview;
}
.audio-background {
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 24px 0;
  position: relative;
}

//overall player container

.audio-player-container {
    width: 90%;
    height: 56px;
    margin: 0px auto 24px auto;
}

.audio-player{
  align-items: center;
  background-color: #EFF1F2;
  border: 1px solid #EFF1F2;
  border-radius: 40px;
  bottom: 32px;
  display: flex;
  height: 56px;
  padding: var(--spacing_sm_200);
  right: 24px;
}

//play button icon
.audio-player .play {
  background-image: $audio-player-button-play-icon;
  background-position: center;
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
  margin: 4px 4px;
  background-color: transparent;
  border: 0px;
}

//stop button icon
.audio-player .stop {
  background-image: $audio-player-button-stop-icon;
  background-position: center;
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
  margin: 4px 4px;
  background-color: transparent;
  border: 0px;
}

//pause button icon
.audio-player .pause {
  background-image: $audio-player-button-pause-icon;
  background-position: center;
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
  margin: 4px 4px;
  background-color: transparent;
  border: 0px;
}

//volume and mute icons
.audio-player .volume {
  background-position: center;
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
  margin: 4px 4px;
  background-color: transparent;
  border: 0px;
  &.muted {
    background-image: $audio-player-button-mute-icon;
    border: 0px;
  }
  &.sound-on{
    background-image: $audio-player-button-volume-icon;
    border: 0px;
  }
}

.volume-slider {
  position: relative;
}

.duration-readout {
  align-items: center;
  background: transparent;
  border: 0;
  color: #222428;
  border: none;
  display: inline-block;
  font-family: var(--typography_paragraph_font-family_lg);
  font-size: inherit;
  gap: var(--spacing_sm_200);
  justify-content: center;
  margin: auto 0px;
  padding: 0 12px 0 0;
  text-decoration: none;
  white-space: nowrap;
  width: fit-content;
}

.player-dashboard {
  display: flex;
  width: fit-content;
}

.player-volume {
  display: flex;
  width: fit-content;
}
.progress-container{
  align-items: center;
  display: flex;
  height: 12px;
  position: relative;
  width: 100%;
}

.progress-bar-container {
  align-items: center;
  background-color: #D3D7D8;
  border-radius: 4px;
  display: flex;
  height: 4px;
  position: relative;
  width: 100%;
}

//progress container
div input[type="range" i].progress-bar {
  -webkit-appearance: none;
  appearance: none;
  background-color: #D3D7D8;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 4px;
  margin: 0px;
  position: absolute;
  width: 100%;
  &::-webkit-slider-thumb  {
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 36px;
    width: 18px;
  }
}

div input[type="range" i].progress-bar.drag{
  -webkit-appearance: none;
  appearance: none;

  &::-webkit-slider-thumb  {
    -webkit-appearance: none;
    appearance: none;
    background-color: #717E8B;
    border-radius: 4px;
    border-radius: 4px;
    height: 12px;
    width: 4px;
  }
}


div input[type="range" i].progress-bar::-moz-range-thumb {
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 36px;
  width: 18px;
}
div input[type="range" i].progress-bar::-ms-thumb{
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 36px;
  width: 18px;
}

//progress bar
div.progress-indicator {
  background-color: #717E8B;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  height: 4px;
  margin: 0px;
  pointer-events: none;
  position: inherit;
  z-index: 3;
}
div.progress-indicator .thumb {
  background-color: #717E8B;
  border-radius: 4px;
  border-radius: 4px;
  content: "";
  height: 12px;
  left: 100%;
  position: absolute;
  top: -4px;
  width: 4px;
  z-index: 4;
}
.progress-readout {
  padding: 0 var(--spacing_sm_400);
}

///Volume controls

.player-volume {
  h1 {
    color: white;
    font-family: cursive;
    margin-top: 20px;
  }

  .volume-container {
    align-items: center;
    background: #EFF1F2;
    border-radius: 32px;
    bottom: 61px;
    display: flex;
    height: 144px;
    position: absolute;
    right: 12px;
    width: 32px;
  }

  .volume-control {
    align-items: center;
    background: transparent;
    border-radius: 32px;
    display: flex;
    height: 120px;
    justify-content: center;
    position: relative;
    width: 32px;
  }

  input[type="range" i].volume-input {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 4px;
    outline: none;
    // background: #D3D7D8;
    padding: 19px 0px;
    rotate: -90deg;
    width: 120px;
    z-index: 2;
    &::-webkit-slider-runnable-track {
      align-self: center;
      -webkit-appearance: none;
      background-color: #D3D7D8;
      border-radius: 4px;
      box-sizing: border-box;
      display: block;
      flex: 1 1 0%;
      height: 4px;
      width: 120px;
    }
  }

  input[type="range" i].volume-input::-moz-range-track{
    align-self: center;
    appearance: none;
    background-color: #D3D7D8;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    flex: 1 1 0%;
    height: 4px;
    width: 120px;
  }

  input[type="range" i].volume-input::-ms-track{
    align-self: center;
    appearance: none;
    background-color: #D3D7D8;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    flex: 1 1 0%;
    height: 4px;
    width: 120px;
  }

  input[type="range" i].volume-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #717E8B;
    border-radius: 50%;
    box-sizing: border-box;
    color: #717E8B;
    cursor: pointer;
    display: block;
    height: 10px;
    position: relative;
    top: -3px;
    width: 10px;
  }

  input[type="range" i].volume-input::-moz-range-thumb{
    appearance: none;
    background: #717E8B;
    border-radius: 50%;
    box-sizing: border-box;
    color: #717E8B;
    cursor: pointer;
    display: block;
    height: 10px;
    position: relative;
    top: -3px;
    width: 10px;
  }

  input[type="range" i].volume-input::-ms-thumb{
    appearance: none;
    background: #717E8B;
    border-radius: 50%;
    box-sizing: border-box;
    color: #717E8B;
    cursor: pointer;
    display: block;
    height: 10px;
    position: relative;
    top: -3px;
    width: 10px;
  }

  .volume-fill {
    align-self: flex-end;
    background-color: #717E8B;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 120px;
    position: absolute;
    width: 4px;
    z-index: 3;
  }
}

.admin .audio-wrapper {
  max-height: 516px;
  max-width: 752px;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}


